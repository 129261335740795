import React from "react";
import { useEffect, useState } from "react";
import numeral from "numeral";
import Select from "react-select";
import Clamp from "react-multiline-clamp";
import carrierList from "../../../utils/carrierList.json";
import { awsUrl } from "../../../config";
import { blobPdfFromBase64String } from "../../../utils/common";
import axios from "axios";
import SmallLoader from "../../common/SmallLoader";
import ExcelJS from "exceljs";
import riskprofile from "../../../images/RiskProfile.svg";

const carrierListMapping = carrierList.carrierMapping;

const RiskProfile = (props) => {
  let commonPercentage = 100;
  const [data, setData] = useState({});
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showDropdown, setshowDropdown] = useState(false);
  const [carrierDropdown, setCarrierDropdown] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState();
  const [selectedPremium, setSelectedPremium] = useState({
    value: "manual_premium",
    label: "Manual Premium",
  });
  const [premiumDropdown, setPremiumDropdown] = useState([
    {
      value: "manual_premium",
      label: "Manual Premium",
    },
  ]);

  useEffect(() => {
    const fetchRiskProfile = async () => {
      try {
        let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
        let user_id = sessionStorage.getItem("user_id");

        let { riskProfile, riskProfileId } = currProspect;
        if (riskProfile && JSON.stringify(riskProfile) !== "{}") {
          setData(riskProfile);
        } else if (riskProfileId) {
          // create an api - instead of direct url. secure s3 bucket - fetch status from userTable.
          let riskProfileUrl, status;
          setIsLoading(true);
          // Retry mechanism
          const maxRetries = 5;
          let attempts = 0;
          let riskProfileData;
          //retry with api call to fetch signed url.
          while (attempts < maxRetries) {
            try {
              let res = await axios.post(
                awsUrl + "/api/getRiskProfileSignedUrl",
                JSON.stringify({
                  user_id,
                })
              );
              status = res?.data?.status;

              riskProfileUrl = res?.data?.signedUrl || "";
              if (!status) {
                attempts = 5;
                setIsLoading(false);
                setMessage("Error fetching risk profile");
                return;
              }
              if (status === "Completed") {
                const response = await axios.get(riskProfileUrl);
                riskProfileData = response.data;
              }

              if (riskProfileData && JSON.stringify(riskProfileData) !== "{}") {
                setData(riskProfileData);
                setIsLoading(false);

                return; // Exit if successful
              }
            } catch (error) {
              console.error("Error fetching risk profile data:", error);
              setIsLoading(false);
            }

            attempts++;
            if (attempts < maxRetries) {
              // Wait for 5 seconds before the next attempt
              await new Promise((resolve) => setTimeout(resolve, 5000));
            } else {
              setMessage("Error fetching risk profile");
            }
          }

          if (!riskProfileData) {
            console.error(
              "Failed to fetch risk profile data after multiple attempts."
            );
          }
        }
      } catch (error) {
        console.error("Error in risk profile:", error);
      }
    };

    // Run fetchRiskProfile initially
    fetchRiskProfile();
  }, []); // Empty dependency array to run once on mount
  useEffect(() => {
    setCarrierList();
  }, [data]);

  const handleSvgClick = () => {
    setshowDropdown(!showDropdown);
  };

  const setCarrierList = () => {
    let carrierList = [];
    let carrierMap = {};
    let peoDetails = {};
    let selectedCarrierlist = [];
    let preparedCarrierlist = [];
    try {
      peoDetails = JSON.parse(sessionStorage.getItem("currProspect"))
        .peoDetails;

      let riskProfileList = JSON.parse(JSON.stringify(data));
      riskProfileList = Object.keys(riskProfileList);

      selectedCarrierlist = JSON.parse(sessionStorage.getItem("currProspect"))
        .carrierList?.value;

      for (let key of selectedCarrierlist) {
        if (riskProfileList.includes(key.value)) {
          preparedCarrierlist.push({ label: key.label, value: key.value });
        }
      }
    } catch (error) {}

    // for (let key in data) {
    //   carrierList.push({
    //     label:
    //       key && peoDetails.selectedPeo !== "demo"
    //         ? carrierListMapping[key]
    //         : key?.toUpperCase(),
    //     value: key,
    //   });
    // }

    // carrierList.sort((a, b) => (a.label > b.label ? 1 : -1));

    setCarrierDropdown(preparedCarrierlist);
  };

  const createTable = (table, allTableData) => {
    let rows = [];
    let tableDetailsMap = {
      eligibility: {
        tableDataKey: "eligibilityData",
        tableName: "Eligibility Profile",
        tableHeader: "Eligibility",
      },
      sic: {
        tableDataKey: "sicData",
        tableName: "SIC Group Profile",
        tableHeader: "Industry Classification",
      },
      hazard: {
        tableDataKey: "hazardData",
        tableName: "Best Hazard Profile",
        tableHeader: "Best Composite",
      },
      naics: {
        tableDataKey: "naicsData",
        tableName: "NAICS Group Profile",
        tableHeader: "Industry Classification",
      },
      state: {
        tableDataKey: "stateData",
        tableName: "State Summary",
        tableHeader: "States",
      },
      stateHazard: {
        tableDataKey: "stateHazardData",
        tableName: "State Hazard Combination",
        tableHeader: "State-Hazard",
      },
    };

    if (table in tableDetailsMap) {
      let tableData = allTableData[tableDetailsMap[table].tableDataKey];
      tableData = sortTableData(tableData);
      for (let key in tableData) {
        rows.push(
          <tr key={table + "-" + key + "row"} className="table-rows">
            <td className="key" title={key}>
              <Clamp lines={1}>{key}</Clamp>
            </td>
            <td className="payroll">
              {numeral(tableData[key].payroll).format("$0,0")}
            </td>
            <td className="premium">
              {numeral(tableData[key].manualPremium).format("$0,0")}
            </td>
            <td className="mix-rate">
              {(tableData[key].mixRate * 100).toFixed(2)}%
            </td>
            <td className="wage-mix">
              {(tableData[key].wageMix * 100).toFixed(2)}%
            </td>
            <td className="premium-mix">
              {(tableData[key].premiumMix * 100).toFixed(2)}%
            </td>
          </tr>
        );
      }

      rows.push(
        <tr key={table + "-" + "total"} className="totals-row">
          <td className="Total-riskprofile">Total</td>
          <td>{numeral(allTableData.totalData.payroll).format("$0,0")}</td>
          <td>
            {numeral(allTableData.totalData.manualPremium).format("$0,0")}
          </td>
          <td>{(allTableData.totalData.mixRate * 100).toFixed(2)}%</td>
          <td>{commonPercentage.toFixed(2)}%</td>
          <td>{commonPercentage.toFixed(2)}%</td>
        </tr>
      );

      return (
        <div className="riskProfile-table-responsive">
          <table key={table + "-table"} className={`table ${table}-table`}>
            <tr className="table-header">
              <th rowSpan={rows.length + 1} className="table-name">
                {tableDetailsMap[table].tableName}
              </th>
              <th className="key-header">
                {tableDetailsMap[table].tableHeader}
              </th>
              <th className="payroll-header">Est. Payroll</th>
              <th className="premium-header">Man. Prem.</th>
              <th className="mix-rate-header">Mix Rate</th>
              <th className="wage-mix-header">Payroll Mix</th>
              <th className="premium-mix-header">Prem Mix</th>
            </tr>
            {rows}
          </table>
        </div>
      );
    }
  };

  const sortTableData = (tableData) => {
    let response = {};
    let sortList = [];
    try {
      for (let key in tableData) {
        sortList.push({
          key,
          ...tableData[key],
        });
      }
      sortList.sort((a, b) => a.manualPremium - b.manualPremium);
      for (let element of sortList) {
        let key = element.key;
        delete element.key;
        response[key] = element;
      }
    } catch (error) {
      console.log(error);
      response = tableData;
    }

    return response;
  };

  const sortTables = (carrierData) => {
    let response = {};
    let sortOrder = [
      "eligibility",
      "sic",
      "naics",
      "hazard",
      "state",
      "stateHazard",
    ];
    try {
      for (let key of sortOrder) {
        if (key in carrierData) {
          response[key] = carrierData[key];
        }
      }
    } catch (error) {
      console.log(error);
      response = carrierData;
    }

    return response;
  };

  const createAllTables = (carrier) => {
    let tables = [];
    if (carrier in data) {
      let carrierData = JSON.parse(JSON.stringify(data[carrier]));
      carrierData = sortTables(carrierData);
      for (let tableKey in carrierData) {
        tables.push(createTable(tableKey, carrierData[tableKey]));
        tables.push(<div key={tableKey + "spacing"} className="mt-5"></div>);
      }
    } else if (carrier) {
      tables = <div className="mt-4">No data for the selected carrier</div>;
    } else {
      tables = <div className="mt-4">Please select a carrier</div>;
    }
    return tables;
  };

  const downloadRiskProfile = (fileType) => {
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    if (fileType === "pdf") {
      if (
        data?.[selectedCarrier?.value || "#1256793"] &&
        currProspect?.companyProfile?.companyName?.value &&
        selectedCarrier?.label
      )
        axios
          .post(
            awsUrl + "/api/generateRiskProfile",
            JSON.stringify({
              data_to_print: data[selectedCarrier.value],
              comp_name: currProspect.companyProfile.companyName.value,
              carrier_name: selectedCarrier.label,
            })
          )
          .then((res) => {
            let pdf = res.data.data;
            let file = blobPdfFromBase64String(pdf);
            var fileURL = URL.createObjectURL(file);

            let blobUrl = fileURL;
            const link = document.createElement("a");

            // create a blobURI pointing to our Blob
            link.href = blobUrl;
            link.download = `RiskProfile_${selectedCarrier.label}`;

            // some browser needs the anchor to be in the doc
            document.body.append(link);
            link.click();

            try {
              link.remove();

              // in case the Blob uses a lot of memory
              setTimeout(() => URL.revokeObjectURL(link.href), 7000);
            } catch (error) {}
          })
          .catch((err) => {
            console.log("Error ", err);
          });
      setshowDropdown(false);
    } else if (fileType === "xlsx") {
      if (
        data?.[selectedCarrier?.value || "#1256793"] &&
        currProspect?.companyProfile?.companyName?.value &&
        selectedCarrier?.label
      ) {
        // Function to format dollar values
        const formatDollar = (value) => {
          if (value === undefined || value === null) return "$0.00";

          // Ensure the value is a number and format it with commas
          const formattedValue = parseFloat(value).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

          return `$${formattedValue}`;
        };

        // Function to convert to percentage format
        const convertToPercentage = (value) => {
          if (value === undefined || value === null) return "0.00%";
          return `${(value * 100).toFixed(2)}%`;
        };

        // Function to prepare data for each section (eligibility, SIC, NAICS, hazard, state)
        const prepareSectionData = (
          sectionData,
          sectionTitle,
          headers,
          totalData,
          worksheet
        ) => {
          // Add Section Title and apply formatting
          worksheet.addRow([sectionTitle]);
          const titleRow = worksheet.lastRow;
          titleRow.font = { bold: true, size: 14 }; // Optional: You can adjust the size and boldness

          // Add Header Row and make bold
          const headerRow = worksheet.addRow(headers);
          headerRow.font = { bold: true }; // Make header bold

          // Iterate through each category/row in the section
          for (const category in sectionData) {
            if (Object.hasOwnProperty.call(sectionData, category)) {
              const categoryData = sectionData[category];
              // Only add the category row if there's data
              if (Object.keys(categoryData).length > 0) {
                const row = worksheet.addRow([
                  category.charAt(0).toUpperCase() + category.slice(1), // Capitalize category name
                  formatDollar(categoryData.payroll),
                  formatDollar(categoryData.manualPremium),
                  convertToPercentage(categoryData.mixRate),
                  convertToPercentage(categoryData.wageMix),
                  convertToPercentage(categoryData.premiumMix),
                ]);
              }
            }
          }

          // Add total row for the section (check if totalData exists)
          const totalRow = worksheet.addRow([
            "Total",
            totalData ? formatDollar(totalData.payroll) : "$0.00",
            totalData ? formatDollar(totalData.manualPremium) : "$0.00",
            totalData ? convertToPercentage(totalData.mixRate) : "100.00%",
            "100.00%", // Assuming total mix rate is always 100%
            "100.00%", // Assuming total premium mix is always 100%
          ]);

          worksheet.addRow([]); // Add an empty row for spacing
        };

        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Risk Profile");

        // Add static information to the sheet
        worksheet.addRow(["", "", "Risk Profile Summary"]);
        const riskProfileRow = worksheet.lastRow;
        riskProfileRow.font = { bold: true, size: 16 }; // Bold and font size 16
        worksheet.addRow([]); // Empty row for spacing

        // Add Insured and Carrier rows with bold labels
        worksheet.addRow([
          "Insured: ",
          currProspect?.companyProfile?.companyName?.value,
        ]);
        const insuredRow = worksheet.lastRow;
        insuredRow.font = { bold: true }; // Make "Insured" label bold

        worksheet.addRow(["Carrier: ", selectedCarrier?.label]);
        const carrierRow = worksheet.lastRow;
        carrierRow.font = { bold: true }; // Make "Carrier" label bold
        worksheet.addRow([]); // Empty row for spacing

        // 1. Prepare Eligibility Data
        const eligibilityData = data[selectedCarrier.value]?.eligibility;
        if (eligibilityData) {
          const eligibilityHeaders = [
            "Eligibility",
            "Est. Payroll",
            "Manual Premium",
            "Mix Rate",
            "Payroll Mix",
            "Premium Mix",
          ];
          const eligibilityDetails = eligibilityData?.eligibilityData || {};
          const totalEligibilityData = eligibilityData?.totalData || {};
          prepareSectionData(
            eligibilityDetails,
            "Eligibility Profile",
            eligibilityHeaders,
            totalEligibilityData,
            worksheet
          );
        }

        // 2. Prepare SIC Data
        const sicData = data[selectedCarrier.value]?.sic;
        if (sicData) {
          const sicHeaders = [
            "SIC",
            "Est. Payroll",
            "Manual Premium",
            "Mix Rate",
            "Payroll Mix",
            "Premium Mix",
          ];
          const sicDetails = sicData?.sicData || {};
          const totalSicData = sicData?.totalData || {};
          prepareSectionData(
            sicDetails,
            "SIC Group Profile",
            sicHeaders,
            totalSicData,
            worksheet
          );
        }

        // 3. Prepare NAICS Data
        const naicsData = data[selectedCarrier.value]?.naics;
        if (naicsData) {
          const naicsHeaders = [
            "Industry Classification",
            "Est. Payroll",
            "Manual Premium",
            "Mix Rate",
            "Payroll Mix",
            "Premium Mix",
          ];
          const naicsDetails = naicsData?.naicsData || {};
          const totalNaicsData = naicsData?.totalData || {};
          prepareSectionData(
            naicsDetails,
            "NAICS Group Profile",
            naicsHeaders,
            totalNaicsData,
            worksheet
          );
        }

        // 4. Prepare Hazard Data
        const hazardData = data[selectedCarrier.value]?.hazard;
        if (hazardData) {
          const hazardHeaders = [
            "Best Composite",
            "Est. Payroll",
            "Manual Premium",
            "Mix Rate",
            "Payroll Mix",
            "Premium Mix",
          ];
          const hazardDetails = hazardData?.hazardData || {};
          const totalHazardData = hazardData?.totalData || {};
          prepareSectionData(
            hazardDetails,
            "Best Hazard Profile",
            hazardHeaders,
            totalHazardData,
            worksheet
          );
        }

        // 5. Prepare State Data
        const stateData = data[selectedCarrier.value]?.state;
        if (stateData) {
          const stateHeaders = [
            "States",
            "Est. Payroll",
            "Manual Premium",
            "Mix Rate",
            "Payroll Mix",
            "Premium Mix",
          ];
          const stateDetails = stateData?.stateData || {};
          const totalStateData = stateData?.totalData || {};
          prepareSectionData(
            stateDetails,
            "State Summary",
            stateHeaders,
            totalStateData,
            worksheet
          );
        }

        const stateHazardData = data[selectedCarrier.value]?.stateHazard;
        if (stateHazardData) {
          const stateHazardHeaders = [
            "State-Hazard",
            "Est. Payroll",
            "Manual Premium",
            "Mix Rate",
            "Payroll Mix",
            "Premium Mix",
          ];
          const stateHazardDetails = stateHazardData?.stateHazardData || {};
          const totalStateHazardData = stateHazardData?.totalData || {};
          prepareSectionData(
            stateHazardDetails,
            "Best State Hazard Profile",
            stateHazardHeaders,
            totalStateHazardData,
            worksheet
          );
        }

        // Write the Excel file to a buffer
        workbook.xlsx.writeBuffer().then((buffer) => {
          // Create a Blob from the buffer
          const xlsxBlob = new Blob([buffer], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          // Create a download link and trigger the download
          const link = document.createElement("a");
          link.href = URL.createObjectURL(xlsxBlob);
          link.download = `RiskProfile_${selectedCarrier.label}.xlsx`;

          // Trigger the download
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
        setshowDropdown(false);
      }
    }
  };

  return (
    <div>
      <div
        className="pink-header font-family-montserrat-bold"
        style={{ fontSize: "x-large" }}
      >
        Risk Profile
      </div>
      {isLoading ? (
        <>
          <div>Please Wait...</div>
          <SmallLoader />
        </>
      ) : (
        <>
          {!message ? (
            <div id="riskprofile-table" className="container-fluid">
              <div className="row no-gutters pb-5 d-flex justify-content-between">
                <div className="d-flex dropdown__container">
                  <div className="d-flex align-items-center dropdown__container_child">
                    <label className="font-weight-bold pl-3">
                      Select Program
                    </label>
                    <div className="pl-3">
                      <Select
                        placeholder={"Please Select"}
                        value={selectedCarrier}
                        className="carrier-dropdown dropdown"
                        options={carrierDropdown}
                        onChange={(selectOption) => {
                          setSelectedCarrier(selectOption);
                        }}
                      />
                    </div>
                  </div>

                  {/* <div className="d-flex align-items-center">
              <label className="font-weight-bold pl-5">Premium Type</label>
              <div className="pl-3">
                <Select
                  isDisabled={true}
                  placeholder="Manual Premium"
                  className="premium-dropdown dropdown"
                  value={selectedPremium}
                  options={premiumDropdown}
                  onChange={(selectOption) => setSelectedPremium(selectOption)}
                />
              </div>
            </div> */}
                </div>
                {selectedCarrier?.value && (
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    {/* SVG Trigger */}
                    <div
                      onClick={handleSvgClick}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={riskprofile}
                        alt="Risk Profile"
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "8px",
                        }}
                      />
                      <span className="risk_profile_text">Export</span>
                    </div>
                    {/* Dropdown Menu */}
                    {showDropdown &&
                      selectedCarrier?.value &&
                      data?.[selectedCarrier.value] && (
                        <div
                          style={{
                            position: "absolute",
                            top: "100%",
                            right: 0,
                            backgroundColor: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            zIndex: 1000,
                            width: "170px",
                          }}
                        >
                          <div
                            className="risk_profile_dropdown"
                            onClick={() => downloadRiskProfile("pdf")}
                          >
                            Download PDF
                          </div>
                          <div
                            className="risk_profile_dropdown"
                            onClick={() => downloadRiskProfile("xlsx")}
                          >
                            Download XLSX
                          </div>
                        </div>
                      )}
                  </div>
                )}
              </div>

              {createAllTables(selectedCarrier?.value)}
            </div>
          ) : (
            <div> {message}</div>
          )}
        </>
      )}
    </div>
  );
};

export default RiskProfile;
